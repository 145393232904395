import styled from 'styled-components';

import { BREAKPOINTS } from '../../../utils/theme';

export const ContactCardListContainer = styled.div`
  padding: 60px;

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    padding: 20px;
  }
`;

export const ContactCardListTitle = styled.h2`
  font-weight: 600;
  font-size: 14px;
`;
