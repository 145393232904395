import React, { useEffect, useState } from 'react';

import { contentFetch } from '../../../lib/content-fetcher';

import { SupportersContainer, SupporterName, SupporterNames, SupportersTitle } from './styles';

const Supporters = () => {
  const [supporters, setSupporters] = useState([]);

  useEffect(() => {
    contentFetch({
      pathname: '/supporters',
      setState: setSupporters,
    });
  }, []);

  return supporters.length ? (
    <SupportersContainer>
      <SupportersTitle>SUPPORTERS</SupportersTitle>
      <SupporterNames>
        {supporters.map((supporter) => (
          <SupporterName>{supporter.name}</SupporterName>
        ))}
      </SupporterNames>
    </SupportersContainer>
  ) : null;
};

export default Supporters;
