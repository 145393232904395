import styled from 'styled-components';

import { MemberName } from '../../Member/styles';

import { BREAKPOINTS } from '../../../utils/theme';

export const SupportersContainer = styled.div`
  padding: 60px;

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    padding: 20px;
  }
`;

export const SupportersTitle = styled.h2`
  font-weight: 600;
  font-size: 14px;
`;

export const SupporterNames = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 900px;
`;

export const SupporterName = styled(MemberName)`
  width: 32%;

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    width: 49%;
  }

  @media (max-width: ${BREAKPOINTS.MOBILE}px) {
    width: 100%;
  }
`;
