import * as React from 'react';
import {
  Container,
  BackgroundContainer,
  HeaderContainer,
  MemberName,
  MemberCompany,
  MemberImage,
  MemberTag,
  MemberDesc,
  Plus,
  PlusWhite,
  Tooltip,
  TooltipText,
} from './styles';

import { addTargetBlankToLinks } from '../../utils/html';
import { PROFILE_PLACEHOLDER } from '../../lib/contants';
import { contentFetch } from '../../lib/content-fetcher';

const Member = ({
  small,
  big,
  fixedWidth,
  hasMarginRight,
  member,
  marginRBig,
  summary,
  user,
  onMemberClick = () => {},
  ...rest
}) => {
  const [isOpenTooltip, setOpenTooltip] = React.useState(false);
  const tooltipTextRef = React.useRef(null);

  const toggleIsOpenTooltip = () => {
    if (!isOpenTooltip) {
      const data = {
        from: user.id,
        to: member.id,
      };
      contentFetch({
        pathname: '/user-contact-counters',
        method: 'post',
        data,
      });
    }
    member.contactDetails && setOpenTooltip(!isOpenTooltip);
  };

  React.useLayoutEffect(() => {
    if (tooltipTextRef?.current) {
      addTargetBlankToLinks([tooltipTextRef.current]);
    }
  }, [tooltipTextRef]);

  return (
    <Container
      big={big}
      small={small}
      fixedWidth={fixedWidth}
      hasMarginRight={hasMarginRight}
      marginRBig={marginRBig}
      isOpenTooltip={isOpenTooltip}
      onClick={() => onMemberClick(member.id)}
    >
      <BackgroundContainer />
      <HeaderContainer>
        {big && user && (
          <>
            <Tooltip isOpen={member.contactDetails && isOpenTooltip}>
              <TooltipText
                ref={tooltipTextRef}
                dangerouslySetInnerHTML={{ __html: member.contactDetails }}
              />
            </Tooltip>
            <Plus onClick={toggleIsOpenTooltip} rotate={isOpenTooltip} />
            <PlusWhite onClick={toggleIsOpenTooltip} rotate={isOpenTooltip} />
          </>
        )}
        <MemberName>
          {member.firstName} {member.lastName}
        </MemberName>
        <MemberCompany>{member.organizationName}</MemberCompany>
      </HeaderContainer>
      <MemberImage
        {...rest}
        image={member?.image?.formats?.medium?.url || member?.image?.url || PROFILE_PLACEHOLDER}
      >
        {member?.occupation && (
          <MemberTag color={member?.occupation?.hexcolor}>{member?.occupation?.label}</MemberTag>
        )}
      </MemberImage>
      {summary && member?.summary && <MemberDesc>{member?.summary}</MemberDesc>}
    </Container>
  );
};

export default Member;
