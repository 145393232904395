import styled from 'styled-components';
import { COLORS, BREAKPOINTS, H4, H5, Ps } from '../../utils/theme';

import PlusImage from '../../images/plus.svg';
import PlusImageWhite from '../../images/plus-white.svg';
import backgroundImg from '../../images/violet-2.png';

export const MemberImage = styled.div`
  width: 100%;
  flex: 1;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center;
  position: relative;
  transition: transform 0.5s;
  z-index: 2;
  pointer-events: none;

  ${(props) =>
    props.marginR &&
    `
        margin-right: 16px;
        width: calc(100% - 16px);
    `}
  ${(props) =>
    props.marginL &&
    `
        margin-left: 16px;
        width: calc(100% - 16px);
    `}
    ${(props) =>
    props.marginT &&
    `
        margin-top: 16px;
        height: calc(100% - 16px);
    `}
    ${(props) =>
    props.marginB &&
    `
        margin-bottom: 16px;
        height: calc(100% - 16px);
    `}

    ${(props) =>
    props.image &&
    `
        background-image: url(${props.image});
      `}
`;

export const MemberName = styled(H4)`
  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: 0.43px;
    margin-bottom: 5px;
  }
`;

export const MemberCompany = styled(H5)`
  text-transform: uppercase;
  line-height: 1.5;
  letter-spacing: 0.34px;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.26px;
  }
`;

export const MemberDesc = styled(Ps)`
  display: flex;
  padding: 0px 12px;
  overflow-y: hidden;
  color: ${COLORS.BLACK};
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.16px;
`;

export const BackgroundContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: ${COLORS.BACKGROUND_LIGHT};
`;

export const Plus = styled.div`
  width: 40px;
  height: 40px;
  background-image: url(${PlusImage});
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  right: 0;
  transform: rotate(0deg);
  transition-property: transform;
  transition-duration: 0.2s;

  ${(props) =>
    props.rotate &&
    `
    transform: rotate(225deg);
  `}

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    display: none;
  }
`;

export const PlusWhite = styled(Plus)`
  background-image: url(${PlusImageWhite});
  transform: rotate(0deg);
  transition-property: transform;
  transition-duration: 0.2s;

  ${(props) =>
    props.rotate &&
    `
    transform: rotate(225deg);
  `}
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${COLORS.BLACK};
  z-index: 1;
  position: relative;
  padding: 6px 0px;
  box-sizing: border-box;

  ${(props) =>
    props.small &&
    `
    flex: 1;
    height: 335px;
  `}

  ${(props) =>
    props.big &&
    `
    cursor: pointer;
    z-index: 1;
    flex-basis: 24%;
    height: 458px;

    &:nth-child(7n + 2) {
      margin-right: 16px;

      @media (max-width: ${BREAKPOINTS.PHABLET}px) {
        margin-right: 0 !important;
      }
    }
    &:nth-child(7n + 5) {
      margin-right: 32px;

      @media (max-width: ${BREAKPOINTS.PHABLET}px) {
        margin-right: 0 !important;
      }
    }
    &:nth-child(7n + 6) {
      margin-left: 32px;

      @media (max-width: ${BREAKPOINTS.PHABLET}px) {
        margin-left: 0 !important;
      }
    }

    ${MemberName} {
      margin-right: 40px;

      @media (max-width: ${BREAKPOINTS.PHABLET}px) {
        margin-right: 0;
      }
    }

    ${MemberImage} {
      min-height: 290px;
      max-height: 290px;
      transition-property: all;
      transition-duration: 0.5s;
      transition-timing-function: ease;
      transition-delay: 0s;
      @media (max-width: ${BREAKPOINTS.PHABLET}px) {
        min-height: auto;
        width: 150px;
      }
    }

    ${BackgroundContainer} {
      transition: all .5s;
    }

    ${Plus} {
      display: block;
    }

    ${PlusWhite} {
      display: none;
    }

    &:hover {
      z-index: 9;

      ${BackgroundContainer} {
        background-color: ${COLORS.BLACK};
        // background-image: url(${backgroundImg});
        // background-repeat: no-repeat;
        // background-position: top;
        // background-size: 100%;
      }

      ${MemberName},
      ${MemberCompany} {
        color: ${COLORS.WHITE};
      }

      ${MemberDesc} {
        overflow-y: auto;
        color: ${COLORS.WHITE};
      }

      ${Plus} {
        display: none;
      }

      ${PlusWhite} {
        display: block;
      }

      ${MemberImage} {
        min-height: 200px;
        max-height: 200px;
      }
    }
  `}

  ${(props) =>
    props.fixedWidth &&
    `
    width: 255px;
    flex: none;
  `}

  ${(props) =>
    props.isOpenTooltip &&
    `
      ${PlusWhite} {
        display: block;
        z-index: 10;
        right:
      }
    `}

  ${(props) =>
    props.hasMarginRight &&
    `
    margin-right: 32px !important;

    @media (max-width: ${BREAKPOINTS.PHABLET}px) {
      margin-right: 0 !important;
    }
  `}
  ${(props) =>
    props.marginRBig &&
    `
    margin-right: 100px;

    @media (max-width: ${BREAKPOINTS.PHABLET}px) {
      margin-right: 0;
    }
  `}

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    flex-direction: row;
    height: 128px;
    max-width: 100%;
    overflow: hidden;
    margin-bottom: 5px;

    ${Plus} {
      display: none;
    }

    &:hover {
      ${PlusWhite} {
        display: none;
      }
    }
  }
`;

export const HeaderContainer = styled.div`
  padding: 12px 16px;
  position: relative;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    flex: 1;
    min-width: 60px;
  }
`;

export const MemberTag = styled(Ps)`
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.16px;
  text-align: center;
  padding: 3px 12px;
  border-radius: 8px;
  position: absolute;
  right: 20px;
  top: -15px;
  transform: rotateZ(-15deg);
  background-color: ${COLORS.WHITE};

  ${(props) =>
    props.color &&
    `
    background-color: ${props.color};
    color: ${COLORS.BLACK};
  `}

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    right: initial;
    left: -15px;
    top: -5px;
  }
`;

export const Tooltip = styled.div`
  width: calc(100% - 40px);
  position: absolute;
  top: -2px;
  right: 5px;
  background-color: ${COLORS.BLACK};
  color: ${COLORS.WHITE};
  text-align: center;
  padding: 5px 30px 5px 0;
  border: 1px solid rgba(255, 255, 255, 0.5);
  cursor: default;

  z-index: 10;
  visibility: hidden;
  overflow-y: auto;
  ${({ isOpen }) =>
    isOpen &&
    `
  visibility: visible;
  `}
`;

export const TooltipText = styled.div`
  overflow-y: auto;
  min-height: 35px;
  max-height: 300px;
  padding: 5px;

  a {
    color: ${COLORS.WHITE};
  }
`;
