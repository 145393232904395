import * as React from 'react';
import loadable from '@loadable/component';

import { COLORS } from '../utils/theme';

import SEO from '../components/SEO';
import Background from '../components/Background';
import HeaderTitle from '../components/HeaderTitle';
import BoardMembers from '../components/ContactUs/ContactCardList/BoardMembers';
import CanTeam from '../components/ContactUs/ContactCardList/CanTeam';
import Supporters from '../components/ContactUs/Supporters';
import SubTitleBoxes from '../components/ContactUs/SubTitleBoxes';
import { PAGES_TYPES } from '../utils/constants';

const Header = loadable(() => import('../components/Header'));
const Footer = loadable(() => import('../components/Footer'));

const Tools = () => {
  const [menuIsShown, showMenu] = React.useState(false);

  return (
    <Background color={COLORS.BACKGROUND_LIGHT}>
      <SEO title="Contact Us"></SEO>
      <Header menuIsShown={menuIsShown} showMenu={showMenu} />
      <HeaderTitle menuIsShown={menuIsShown} type={PAGES_TYPES.CONTACT_US} />
      <SubTitleBoxes />
      <BoardMembers menuIsShown={menuIsShown} />
      <CanTeam />
      <Supporters />
      <Footer />
    </Background>
  );
};

export default Tools;
